<template>
  <el-col :span="24">
    <pz-title>
      <span slot="title">
        {{ $t('views.final.greetings') }}
      </span>
      <span slot="sub_title">
        {{ $t('views.final.sent_ok') }}
      </span>
    </pz-title>
    <el-row
      :gutter="20"
      class="pz-font-size-md"
    >
      <el-col
        :span="18"
        :offset="3"
        class="text-center"
      >
        <el-col :span="24">
          {{ $t('views.final.data_received') }}
        </el-col>
        <el-col :span="24">
          {{ $t('views.final.data_received_2') }}
        </el-col>
      </el-col>
    </el-row>

    <el-row>
      <el-col
        :span="24"
        class="row justify-content-center"
      >
        <pz-back-button
          :back="goFirstStep"
          :button-name="$t('views.final.back_button')"
        />
      </el-col>
    </el-row>
  </el-col>
</template>


<script>
    import {mapGetters} from "vuex";
    import PzBackButton from "@/commons/forms/Buttons/PzBackButton";
    export default {
        name: 'PzMessageUy',
        components: {PzBackButton},
        data() {
            return {}
        },
        computed: {
            ...mapGetters(['product', 'form_product', 'current_step']),
        },
        created() {
            this.$store.dispatch('loader/down',{trigger:'route_enter'});
        },
        methods: {
            goFirstStep: function () {
                this.$emit('save');
            }
        },
    }
</script>

<style scoped>

</style>