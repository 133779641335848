import PzSliderSimulator from '@/components/Flujo/Simuladores/SliderSimulator';
import PzDataInputSimulator from '@/components/Flujo/Simuladores/DataInputSimulator';
import PzCompanyForm1Uy from '@/components/Flujo/Formularios/Formularios1/UY/Form1_Empresa';
import PzPersonForm1Uy from '@/components/Flujo/Formularios/Formularios1/UY/Form1_Persona';
import PzPersonForm1Default from '@/components/Flujo/Formularios/Formularios1/UY/Form1_Persona_default';
import PzPersonFormEs1 from '@/components/Flujo/Formularios/Formularios1/ES/Persona/Form1_Persona1';
import PzPersonFormEs2 from '@/components/Flujo/Formularios/Formularios1/ES/Persona/Form1_Persona2';
import PzPersonForm1Mx from '@/components/Flujo/Formularios/Formularios1/MX/Form1_Persona';
import PzCompanyForm1Mx from '@/components/Flujo/Formularios/Formularios1/MX/Form1_Empresa';
import PzCompanyForm2Uy from '@/components/Flujo/Formularios/Formularios2/UY/Form2_Empresa';
import PzPersonFormExtUy from '@/components/Flujo/Formularios/Formularios2/UY/Form2_Persona';
import PzPersonFormExtEs from '@/components/Flujo/Formularios/Formularios2/ES/Form2_Persona';
import PzCompanyFormExtEs from '@/components/Flujo/Formularios/Formularios2/ES/Form2_Empresa';
import PzPersonForm2Mx from '@/components/Flujo/Formularios/Formularios2/MX/Form2_Persona';
import PzCompanyForm2Mx from '@/components/Flujo/Formularios/Formularios2/MX/Form2_Empresa';
import PzGaranteFormUy from '@/components/Flujo/Formularios/Garante/UY/Form_Garante';
import PzGaranteFormMx from '@/components/Flujo/Formularios/Garante/MX/Form_Garante';
import PzCompanyDocumentacion
    from '@/components/Flujo/Formularios/Documentacion/FormDocumentacion_Empresa';
import PzPersonDocumentacion
    from '@/components/Flujo/Formularios/Documentacion/FormDocumentacion_Persona';
import PzOffersUy from '@/components/Flujo/Ofertas/UY/Ofertas';
import PzChequeDescuentoTerceros
    from '@/components/Flujo/Formularios/DescuentoTerceros/DescuentoTerceros_Cheque';
import PzFacturaDescuentoTerceros
    from '@/components/Flujo/Formularios/DescuentoTerceros/DescuentoTerceros_Factura';
import PzValidateBySms from '@/components/Flujo/Formularios/validacionSMS/ValidacionSMS';
import PzProductsAllSelect from '@/components/Flujo/ProductList/SelectProductsAll';
import PzOfertaDescuentosDefault from '@/components/Flujo/Simuladores/Ofertas/descuento/OfertaDescuentosDefault';
import PzOfertaSolicitudDefault from '@/components/Flujo/Simuladores/Ofertas/solicitud/OfertaSolicitudDefault';
import PzOfertaSolicitudTable from '@/components/Flujo/Simuladores/Ofertas/solicitud/OfertaSolicitudTable';
import PzOfertaUpDescuentosUy from '@/components/Flujo/Ofertas/defaults/partsDescuentos/OfertaUpDescuentosDefault';
import PzOfertaUpSolicitudUy from '@/components/Flujo/Ofertas/defaults/partsSolicitud/OfertaUpSolicitudDefault';
import PzOfertaDownSolicitudUy from '@/components/Flujo/Ofertas/defaults/partsSolicitud/OfertaDownSolicitudDefault';
import PzMessageUy  from '@/components/Flujo/Final/Message';

import PzVerificacionCliente from '@/components/Flujo/Formularios/VerificacionCliente/VerificacionCliente';
/* Vistas BNF */
import PzPersonForm1Bnf from '@/components/Flujo/Formularios/Formularios1/BNF/Form1_Persona';
import PzPersonFormExtBnf from '@/components/Flujo/Formularios/Formularios2/BNF/Form2_Persona';
import PzPersonFormExt2Bnf from '@/components/Flujo/Formularios/Formularios2/BNF/Form2_Persona2';

/* Vistas Prestama */
import PzPersonForm1Prestama from '@/components/Flujo/Formularios/Formularios1/PRESTAMA/Form1_Persona';
import PzPersonFormExtPrestama from '@/components/Flujo/Formularios/Formularios2/PRESTAMA/Form2_Persona';
import PzPersonFormExt2Prestama from '@/components/Flujo/Formularios/Formularios2/PRESTAMA/Form2_Persona2';


/* Vistas Fenoreste */
import PzPersonForm1Fenoreste from '@/components/Flujo/Formularios/Formularios1/FENORESTE/Form1_Persona';
import PzPersonFormExtFenoreste from '@/components/Flujo/Formularios/Formularios2/FENORESTE/Form2_Persona';

import PzFirmaDocs from '@/components/Flujo/Formularios/FirmaDocumentos/FirmaDoc';

export default [
    PzOfertaUpDescuentosUy,
    PzOfertaUpSolicitudUy,
    PzOfertaDownSolicitudUy,
    PzOfertaDescuentosDefault,
    PzOfertaSolicitudDefault,
    PzOfertaSolicitudTable,
    PzProductsAllSelect,
    PzPersonFormExtEs,
    PzCompanyFormExtEs,
    PzPersonFormEs1,
    PzPersonFormEs2,
    PzSliderSimulator,
    PzDataInputSimulator,
    PzCompanyForm1Uy,
    PzPersonForm1Default,
    PzPersonForm1Uy,
    PzOffersUy,
    PzCompanyForm2Uy,
    PzPersonFormExtUy,
    PzPersonForm2Mx,
    PzCompanyForm2Mx,
    PzCompanyDocumentacion,
    PzPersonDocumentacion,
    PzChequeDescuentoTerceros,
    PzFacturaDescuentoTerceros,
    PzValidateBySms,
    PzPersonForm1Mx,
    PzCompanyForm1Mx,
    PzGaranteFormUy,
    PzGaranteFormMx,
    PzMessageUy,
    PzVerificacionCliente,
    PzPersonForm1Bnf,
    PzPersonFormExtBnf,
    PzPersonFormExt2Bnf,
    PzPersonForm1Prestama,
    PzPersonFormExtPrestama,
    PzPersonFormExt2Prestama,
    PzFirmaDocs,
    PzPersonForm1Fenoreste,
    PzPersonFormExtFenoreste
];
